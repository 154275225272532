/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useRef, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Col, OverlayTrigger, Popover} from "react-bootstrap";
import {
  CircularProgress,
  Switch,
  FormControlLabel,
  FormGroup,
  Chip,
  Button,
} from "@material-ui/core";
import {withStyles, makeStyles} from "@material-ui/core/styles";
import {purple} from "@material-ui/core/colors";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShip, faPlaneArrival} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {MyIconButton} from "components";

import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import {Types as notifyTypes} from "store/reducers/notifyReducer";
import {Types as selectedTypes} from "store/reducers/selectedReducer";
import {Types as alertTypes} from "store/reducers/alertReducer";

import {
  putNotification,
  readAllNotifications,
} from "services/Notification/notification.service";
import {RetrieveUserInformation} from "services/Auth/usermanager.service";
import {getNotificationsNEW} from "services/Notification/notification.service";

import "./NotifyListMenu.scss";

import {patchProcess} from "services/Process/process.service";

import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  message: {
    width: "100%",
    padding: "8px 10px 0px 10px",
  },
  chipRoot: {
    color: "#FFFFFF",
    height: "15px",
    backgroundColor: "#00B2AF",
  },
  toastRoot: {
    backgroundColor: "#FFFFFF",
    color: "#767676",
    padding: "2px",
    width: "100%",
    minWidth: "391px",
    maxWidth: "391px",
  },
  rootButton: {
    background: "#6F2C91",
    border: 0,
    fontSize: "18px",
    color: "white",
    textTransform: "none",
    fontWeight: "bold",
    width: "110px",
  },
  notchedOutline: {
    border: "1px solid rgba(206, 206, 206, 0.8)",
  },
  focused: {
    borderColor: "#6F2C91",
  },
}));

const PurpleSwitch = withStyles({
  switchBase: {
    color: purple[300],
    "&$checked": {
      color: purple[500],
    },
    "&$checked + $track": {
      backgroundColor: purple[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const NotifyListMenu = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const userId = RetrieveUserInformation().email;

  const {notify, isLoading} = useSelector((state) => state.stateNotify);
  const {data} = useSelector((state) => state.stateProcess);
  const anchorRef = useRef(null);

  const {fnToggle, show, fnClick} = props;
  const [isPriority, setIsPriority] = useState(false);
  const [mySwitch, setMySwitch] = useState(
    JSON.parse(localStorage.getItem("noNotify")) || false,
  );

  const toggleChecked = () => {
    if (!mySwitch || Notification.permission === "denied") {
      setMySwitch(true);
      localStorage.setItem("noNotify", true);
    } else {
      setMySwitch(false);
      localStorage.setItem("noNotify", false);
      Notification.requestPermission();
    }
  };

  function calculateDate(date) {
    var newDate = moment.utc(date).format();
    var local = moment.utc(newDate).local().format();

    const diff = moment(new Date(), "DD/MM/YYYY HH:mm:ss").diff(
      moment(new Date(local), "DD/MM/YYYY HH:mm:ss"),
    );
    const {_data} = moment.duration(diff);
    let result;

    if (_data.years > 0) {
      result = `${_data.years} anos atrás`;
    } else if (_data.months) {
      result = `${_data.months} meses atrás`;
    } else if (_data.days) {
      result = `${_data.days} dias atrás`;
    } else if (_data.hours) {
      result = `${_data.hours} horas atrás`;
    } else if (_data.minutes >= 0) {
      result =
        _data.minutes > 0
          ? `${_data.minutes} minutos atrás`
          : "Alguns segundos atrás";
    }

    return result;
  }

  const handleOnlyPriority = async () => {
    let tempNotify = await notify.filter((f) => f.priority);

    try {
      dispatch({
        type: notifyTypes.NOTIFY_REQUEST,
      });

      dispatch({
        type: notifyTypes.NOTIFY_SUCCESS,
        notify: tempNotify,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (isPriority) {
      handleOnlyPriority();
    } else {
      handleGetNotifications();
    }
  }, [isPriority]);

  const handleClickUserNotification = async (notification) => {
    const process = await data.filter(
      (d) => d.processNumber === notification.processNumber,
    );

    // atualizo a notificação com a minha lida
    await putNotification(notification);

    if (notification !== undefined || notification !== null) {
      //a minha precisa ser marcada como lida
      // eslint-disable-next-line array-callback-return
      notification.recipients.map((i) => {
        if (i.email === userId) i.isRead = true;
      });

      if (process.length > 0 && process[0].priority) {
        patchProcess(process[0]._id, {priority: false});
      }
    }

    dispatch({
      type: selectedTypes.CURRENT_SELECTED,
      currentProcess: process[0],
    });

    if (process.length > 0) {
      history.push(`/process-details/${process[0]._id}`);
    } else {
      dispatch({
        type: alertTypes.SHOW_ALERT,
        open: true,
        message: "Processo marcado para outro analista",
        severity: "info",
      });
    }

    await handleGetNotifications();
  };

  const handleGetNotifications = async () => {
    dispatch({
      type: notifyTypes.NOTIFY_REQUEST,
    });
    const criteria = [];
    //qual cnpj
    criteria.push({
      employer: RetrieveUserInformation().employer._id,
    });
    //Eu estou na lista de pessoas notificadas e ainda não li ?
    criteria.push({
      recipients: {
        $elemMatch: {email: RetrieveUserInformation().email, isRead: false},
      },
    });

    try {
      const {data} = await getNotificationsNEW(
        criteria.length > 0 && JSON.stringify({$and: criteria}),
        "-createdAt",
      );
      dispatch({
        type: notifyTypes.NOTIFY_SUCCESS,
        notify: data.items,
      });
    } catch (error) {
      alert("Ocorreu um erro ao buscar seu historico de notificações");
      console.log("error =>", error);
    }
  };

  /*
  Aparentemente é um método que muda a prioridade dos processos.

  1- Caso a notificação tenha prioridade === true e o processo tenha prioridade === false -> muda prioridade
  do processo pra true;

  2- Caso a notificação tenha prioridade === false e o processo tenha prioridade === true -> muda prioridade
  do processo pra false;

  Comentamos o procedimento pois isso causa, em algumas situações, muitas chamadas ao backend,
  o que às vezes quebra a aplicação. Caso necessário, a lógica deve ser movida para o backend.
  */
  // eslint-disable-next-line no-unused-vars
  const changePriorityProcess = () => {
    notify.forEach((m) => {
      if (m.priority) {
        let tempProcess = data.filter(
          (f) => f.processNumber === m.processNumber && !f.priority,
        );

        if (tempProcess.length > 0) {
          patchProcess(tempProcess[0]._id, {
            priority: true,
          });
        }
      } else {
        let tempProcess = data.filter(
          (f) => f.processNumber === m.processNumber && f.priority,
        );
        if (tempProcess.length > 0) {
          patchProcess(tempProcess[0]._id, {
            priority: false,
          });
        }
      }
    });
  };

  useEffect(() => {
    if (data.length > 0) {
      //Ver justificativa na declaração da função, acima.
      //changePriorityProcess();
    }
  }, [data]);

  const handleAllRead = async (info) => {
    const criteria = [];
    //qual cnpj
    criteria.push({
      employer: RetrieveUserInformation().employer._id,
    });

    //Eu estou na lista de pessoas notificadas e ainda não li ?
    criteria.push({
      recipients: {
        $elemMatch: {email: RetrieveUserInformation().email, isRead: false},
      },
    });

    try {
      dispatch({
        type: notifyTypes.NOTIFY_REQUEST,
      });
      await readAllNotifications({
        // cnpj: RetrieveUserInformation().cnpj[0],
        // client: RetrieveUserInformation().employer._id,
        email: RetrieveUserInformation().email,
      });
      if (!RetrieveUserInformation().roles.includes("Guest")) {
        const response = await getNotificationsNEW(
          criteria.length > 0 && JSON.stringify({$and: criteria}),
          "-createdAt",
        );
        dispatch({
          type: notifyTypes.NOTIFY_SUCCESS,
          notify: response.data.items,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      onToggle={fnToggle}
      show={show}
      placement="bottom-start"
      overlay={
        <Popover
          id={`popover-positioned-bottom`}
          className="popover-notify-list"
        >
          <Popover.Title className="popover-notify-title">
            <Col xs={4} className="p-0 text">
              Notificações
            </Col>
            <Col className="p-0 d-flex justify-content-end myswitch">
              <FormGroup>
                <FormControlLabel
                  className="title-label"
                  control={
                    <PurpleSwitch checked={mySwitch} onChange={toggleChecked} />
                  }
                  label="Desativar alertas na tela"
                  labelPlacement="start"
                />
              </FormGroup>
            </Col>
          </Popover.Title>
          <Popover.Content className="">
            {isLoading ? (
              <Col className="d-flex justify-content-center p-0">
                <CircularProgress style={{color: "#573c66"}} size={30} />
              </Col>
            ) : notify.length === 0 ? (
              <div style={{overflow: "auto", maxHeight: "473px"}}>
                <p style={{textAlign: "center", marginTop: "3px"}}>
                  Não há notificações a serem visualizadas
                </p>
              </div>
            ) : (
              <Col className="p-0 popover-notify-list-content">
                {notify.map((item, index) => {
                  return (
                    <Col
                      key={index}
                      className="pt-2 pb-2"
                      onClick={() => handleClickUserNotification(item)}
                      style={{cursor: "pointer"}}
                    >
                      <Col className="d-flex flex-row p-0">
                        <Col
                          xs={1}
                          className="p-0 d-flex justify-content-center align-items-center"
                        >
                          {item.transportationModality === "Aéreo" ? (
                            <FontAwesomeIcon
                              className="icon-card"
                              title={item.transportationModality}
                              icon={faPlaneArrival}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="icon-card"
                              title={item.transportationModality}
                              icon={faShip}
                            />
                          )}
                        </Col>
                        <Col>
                          {" "}
                          {item.clientReference} | {item.processNumber}
                        </Col>
                        <Col xs={2}>
                          {!item.isRead && (
                            <Chip
                              classes={{
                                root: classes.chipRoot,
                              }}
                              label="Novo!"
                            />
                          )}
                        </Col>
                      </Col>
                      <Col
                        xs={12}
                        className={
                          !item.priority ? "body-message-nm" : "body-message"
                        }
                      >
                        <p dangerouslySetInnerHTML={{__html: item.message}} />
                      </Col>
                      <Col className="date-message">
                        {" "}
                        {calculateDate(item.createdAt)}
                      </Col>
                    </Col>
                  );
                })}
              </Col>
            )}
          </Popover.Content>

          {notify.length > 0 && (
            <div className="popover-notify-footer">
              <Button variant="contained" size="medium" onClick={handleAllRead}>
                Marcar todas como lidas
              </Button>

              <Button
                variant="contained"
                size="medium"
                onClick={() => {
                  setIsPriority(!isPriority);
                  handleOnlyPriority();
                }}
              >
                {!isPriority ? "Apenas importantes" : "Mostrar todas"}
              </Button>
            </div>
          )}
        </Popover>
      }
    >
      <MyIconButton
        anchorRef={anchorRef}
        badgeContent={notify.length}
        fnClick={fnClick}
      >
        <NotificationsNoneOutlinedIcon />
      </MyIconButton>
    </OverlayTrigger>
  );
};

export default NotifyListMenu;
